import styled from "styled-components"
import { device } from "../../../theme"

export const SectionWrap = styled.section`
  .gatsby-image-wrapper {
    text-align: center;
    padding-right: 50px;
  }
  h3 {
    font-size: 20px;
  }
`

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 40px;
  }
`
