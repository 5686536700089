import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Swiper from "../../../components/UI/swiper"
import Heading from "../../../components/Heading"
import Button from "../../../components/UI/button"

import { SectionWrap, BannerSlide, BannerText, Imagen2 } from "./style"

const FullWideSlider = ({ sliderSettings }) => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      allPrismicBanner(sort: { fields: data___numero_de_orden }) {
        edges {
          node {
            data {
              imagen {
                fixed(width: 1300) {
                  ...GatsbyPrismicImageFixed_withWebp
                }
                dimensions {
                  height
                }
              }
              titulo {
                text
              }
              subtitulo {
                text
              }
              link_text {
                text
              }
              link {
                url
              }
            }
          }
        }
      }
    }
  `)
  const banners = data.allPrismicBanner.edges
  return (
    <SectionWrap>
      <Swiper settings={sliderSettings}>
        {banners.map(({ node }) => (
          <div>
            <BannerSlide
              style={{
                background: `url(${node.data.imagen.fixed.src})`,
                height: `${node.data.imagen.dimensions.height}px`,
                width: "100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                margin: "0",
                backgroundSize: "cover",
                position: "relative",
              }}
            >
              <BannerText>
                <div>
                  <h1>{node.data.titulo.text}</h1>
                </div>
                <div>
                  {node.data.subtitulo.text && (
                    <Heading as="h3" content={node.data.subtitulo.text} />
                  )}
                </div>
                <div>
                  {node.data.link.url && (
                    <Button
                      to={node.data.link.url}
                      icon={<i className="flaticon-next" />}
                    >
                      {node.data.link_text.text || "Contáctenos"}
                    </Button>
                  )}
                </div>
              </BannerText>

              {node.data.imagen2 && (
                <Imagen2>
                  <Img fixed={node.data.imagen2.fixed} />
                </Imagen2>
              )}
            </BannerSlide>
          </div>
        ))}
      </Swiper>
    </SectionWrap>
  )
}

FullWideSlider.defaultProps = {
  sliderSettings: {
    slidesPerView: 1,
    loop: true,
    arrows: true,
    centeredSlides: true,
    speed: 1000,
    pagination: true,
    paginationStyle: {
      mt: "50px",
    },
  },
}

export default FullWideSlider
