import styled from "styled-components"
import { device } from "../../../theme"

export const SectionWrapper = styled.section``

export const LeftBox = styled.div`
  @media ${device.medium} {
    margin-bottom: 40px;
  }
`
