import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Heading from "../../../components/Heading"
import Anchor from "../../../components/UI/anchor"
import SectionTitle from "../../../components/UI/section-title"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import BoxImage from "../../../components/box-image/layout-one"
import { SectionWrap } from "./section.style"

const BoxSection = ({ sectionTitleStyle, headingStyle, linkStyle }) => {
  const Data = useStaticQuery(graphql`
    query ServiciosIconsQuery {
      allFile(
        filter: { relativeDirectory: { eq: "servicios-icons" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fixed {
                src
              }
            }
            name
          }
        }
      }
    }
  `)
  const boxContents = Data.allFile.edges
  const encabezados = [
    "Enllante y Desenllante",
    "Montaje de Neumáticos",
    "Rotación de Neumáticos",
    "Diagnóstico de Desgaste",
  ]
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle {...sectionTitleStyle} title="Nuestros Servicios" />
          </Col>
        </Row>
        <Row>
          {boxContents.map((item, index) => {
            return (
              <Col
                lg={3}
                md={6}
                className="box-item"
                key={`box-image-${index}`}
              >
                <BoxImage
                  title={encabezados[index]}
                  imageSrc={item.node.childImageSharp.fixed.src}
                  path="/servicios"
                />
              </Col>
            )
          })}
        </Row>
        <Row>
          <Col lg={12}>
            <Heading {...headingStyle}>
              Challenges are just opportunities in disguise.{" "}
              <Anchor {...linkStyle} path="/">
                Take the challenge!
              </Anchor>
            </Heading>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
}

BoxSection.defaultProps = {
  sectionTitleStyle: {
    mb: "60px",
    responsive: {
      medium: {
        mb: "47px",
      },
    },
  },
  headingStyle: {
    as: "h3",
    fontSize: "18px",
    fontweight: 500,
    lineHeight: 1.4,
    color: "#333333",
    mt: "10px",
    textalign: "center",
  },
  linkStyle: {
    layout: "underline",
    hover: {
      layout: 2,
    },
  },
}

export default BoxSection
