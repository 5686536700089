import React from "react"
import PropTypes from "prop-types"
import Text from "../../../components/Text"
import Button from "../../../components/UI/button"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import SectionTitle from "../../../components/UI/section-title"
import NumberedList, {
  NumberedListItem,
} from "../../../components/UI/numbered-list"
import { SectionWrap, LeftBox } from "./about-area.style"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const AboutArea = props => {
  const features = [
    "Tiempo de entrega inmediata",
    "Asesoría y Soporte Técnico",
    "Productos de calidad reconocida",
    "Precios competitivos",
  ]

  const Data = useStaticQuery(graphql`
    query MontacargaQuery {
      file(relativePath: { eq: "montacarga.jpg" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const { sectionTitleStyle } = props
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={4}>
            <Img fixed={Data.file.childImageSharp.fixed} />
          </Col>
          <Col lg={4}>
            <LeftBox>
              <SectionTitle
                {...sectionTitleStyle}
                title="Con más de 28 años de experiencia en el mercado nacional"
                subtitle="Nosotros"
              />
              <Text
                content="
        Somos una de
        las principales empresas importadoras y comercializadoras de llantas en
        el Perú, representantes de prestigiosas marcas entre las que destacan
        MICHELIN, BRIDGESTONE, FIRESTONE, GOOD YEAR, SOLIDEAL, BF GOODRICH y
        LIMA CAUCHO, productos de calidad que nos permite ofrecer variedad,
        seguridad, garantía y el mejor rendimiento."
                style={{
                  color: "rgb(105, 105, 105)",
                  marginBottom: "30px",
                  textAlign: "justify",
                }}
              />

              <Button to="/nosotros">Ver más</Button>
            </LeftBox>
          </Col>
          <Col lg={4} ml="auto">
            <SectionTitle
              {...sectionTitleStyle}
              subtitle="¿por qué elegirnos?"
            />
            <NumberedList>
              {features.map((feature, i) => (
                <NumberedListItem
                  key={`about-feature-${i}`}
                  path="/servicios"
                  number={i + 1}
                >
                  {feature}
                </NumberedListItem>
              ))}
            </NumberedList>
          </Col>
        </Row>
        <Row>
          <Col>
            <i className="fab fa-whatsapp whatsapp-icon" />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

AboutArea.propTypes = {
  descStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  sectionTitleStyle: PropTypes.object,
}

AboutArea.defaultProps = {
  sectionTitleStyle: {
    mb: "20px",
    align: "justify",
  },
}

export default AboutArea
