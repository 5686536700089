import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { device } from "../../../theme"

export const Row = styled.div``

export const Col = styled.div``

export const VideoSectionWrap = styled(BackgroundImage)`
  padding-top: 150px;
  padding-bottom: 150px;
  @media ${device.medium} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
`
export const Box = styled.div``
