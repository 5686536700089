import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import SectionTitle from "../../../components/UI/section-title"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import { SectionWrap } from "./section.style"
import BoxLargeImage from "../../../components/box-large-image/layout-two"

const BoxSection = ({ sectionTitleStyle, boxStyles }) => {
  const Data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "nuestros-productos" } }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fixed {
                src
              }
            }
            name
          }
        }
      }
    }
  `)
  const boxContents = Data.allFile.edges
  const TiposDeLlantas = [
    {
      text: "LLANTAS PARA CONSTRUCCIÓN",
      link: "/categoria/construccion",
    },
    {
      text: "LLANTAS INDUSTRIALES",
      link: "/categoria/montacarga",
    },
    {
      text: "LLANTAS PARA MINERÍA",
      link: "/categoria/jumbo-minero",
    },
    {
      text: "LLANTAS FORESTALES",
      link: "/categoria/tractor-forestal",
    },
    {
      text: "LLANTAS AGRÍCOLAS",
      link: "/categoria/tractor-agricola",
    },
    {
      text: "LLANTAS PARA CAMIONETAS",
      link: "/categoria/camionetas",
    },
    {
      text: "LLANTAS PARA CAMIONES",
      link: "/categoria/camiones",
    },
    {
      text: "LLANTAS PARA AUTOS",
      link: "/categoria/autos",
    },
  ]
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col xl={12}>
            <SectionTitle {...sectionTitleStyle} title="Nuestros Productos" />
          </Col>
        </Row>
        <Row>
          {boxContents.map((item, index) => (
            <Col lg={3} md={6} className="box-item" key={`box-image-${index}`}>
              <BoxLargeImage
                {...boxStyles}
                imageSrc={item.node.childImageSharp.fixed.src}
                path={TiposDeLlantas[index].link}
                btnText={TiposDeLlantas[index].text}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </SectionWrap>
  )
}

BoxSection.propTypes = {
  sectionTitleStyle: PropTypes.object,
  headingStyle: PropTypes.object,
  linkStyle: PropTypes.object,
  boxStyles: PropTypes.object,
}

BoxSection.defaultProps = {
  boxStyles: {
    headingStyle: {
      color: "#000",
    },
  },
  linkStyle: {
    layout: "underline",
    fontSize: "18px",
    fontWeight: 500,
    lineheight: 1.4,
    color: "primary",
    hover: {
      layout: 2,
    },
  },
  sectionBottomStyle: {
    mt: "40px",
    align: "center",
  },
  sectionTitleStyle: {
    color: "#fff",
  },
}

export default BoxSection
