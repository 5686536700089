import styled from "styled-components"
import { device } from "../../../theme"

const AboutServiceWrapStyle = styled.div`
  padding-top: 50px;
  padding-bottom: 100px;
  background: rgb(255, 255, 255); /* Old browsers */

  position: relative;
  @media ${device.medium} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media ${device.small} {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`

export default AboutServiceWrapStyle
