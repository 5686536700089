import React from "react"
import Sidebar from "../../productos/sidebar"
import Listado from "../../productos/listado"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import { SectionWrapper, LeftBox } from "./style"

const ProductsArea = () => {
  return (
    <SectionWrapper>
      <Container>
        <Row>
          <Col lg={2}>
            <LeftBox>
              <Sidebar />
            </LeftBox>
          </Col>
          <Col lg={8} ml="auto">
            <Listado />
          </Col>
        </Row>
      </Container>
    </SectionWrapper>
  )
}

export default ProductsArea
