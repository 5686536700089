import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import Heading from "../../../components/Heading"
import Swiper from "../../../components/UI/swiper"
import { SectionWrap } from "./style"

const CarouselSlider = ({ sliderSettings }) => {
  const Data = useStaticQuery(graphql`
    query {
      allPrismicMarca {
        edges {
          node {
            data {
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading
              as="h4"
              mb="60px"
              textAlign="center"
              content="Te ofrecemos las mejores marcas"
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Swiper settings={sliderSettings}>
              {Data.allPrismicMarca.edges.map(({ node }) => (
                <div className="item">
                  <img src={node.data.image.fluid.src} alt="Slider" />
                </div>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  )
}

CarouselSlider.defaultProps = {
  sliderSettings: {
    slidesPerView: 6,
    loop: true,
    arrows: true,
    autoplay: true,
    paginationStyle: {
      mt: "50px",
    },
    breakpoints: {
      1499: {
        slidesPerView: 6,
      },

      991: {
        slidesPerView: 3,
      },

      767: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  },
}

export default CarouselSlider
