import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "../../../components/UI/wrapper"
import SectionTitle from "../../../components/UI/section-title"
import Button from "../../../components/UI/button"
import Img from "gatsby-image"
import { ResourcesWrapper, LeftBox, ImageBox } from "./resources-area.style"

const Resources = ({ sectionTitleStyle }) => {
  const Data = useStaticQuery(graphql`
    query AboutResourcesQuery {
      image: file(relativePath: { eq: "mecanico.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ImageData: file(relativePath: { eq: "global.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const ImageData = Data.ImageData.childImageSharp.fluid
  return (
    <ResourcesWrapper fluid={ImageData}>
      <Container fluid>
        <Row alignitems="center">
          <Col lg={{ span: 6, order: 1 }} xs={{ order: 2, span: 12 }}>
            <LeftBox>
              <SectionTitle
                {...sectionTitleStyle}
                title="Somos tu mejor opción para llantas"
              />
              <Button to="/contacto" hover="2">
                Contáctenos
              </Button>
            </LeftBox>
          </Col>
          <Col lg={{ span: 6, order: 2 }} xs={{ order: 1, span: 12 }}>
            <ImageBox>
              <Img fixed={Data.image.childImageSharp.fixed} />
            </ImageBox>
          </Col>
        </Row>
      </Container>
    </ResourcesWrapper>
  )
}

Resources.propTypes = {
  sectionTitleStyle: PropTypes.object,
}

Resources.defaultProps = {
  sectionTitleStyle: {
    align: "left",
    mb: "30px",
    responsive: {
      medium: {
        align: "center",
      },
    },
  },
}

export default Resources
