import styled from "styled-components"

export const SectionWrap = styled.section``

export const Imagen2 = styled.div`
  height: 500px;
  margin-right: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const BannerSlide = styled.div`
  width: 100%;
`

export const BannerSliderWrapper = styled.div`
  && {
    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet-active {
        width: 10px;
        height: 10px;
        vertical-align: middle;
      }
    }
  }
`

export const BannerText = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 40px;
  margin: auto;
  width: 100%;
  text-align: center;

  h1 {
    font-size: 52px;
    margin-bottom: 6px;
    font-weight: 900;
    letter-spacing: 0px;
    text-transform: uppercase;
    line-height: 65px;
    color: #fff;
    margin-top: 0;
    text-shadow: 2px 2px 6px #666;
  }
  h3 {
    font-size: 20px;
    letter-spacing: 0px;
    text-transform: uppercase;
    color: #fff;
    line-height: 70px;
  }
`
