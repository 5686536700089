import React from "react"
import PropTypes from "prop-types"
import AboutServiceWrapStyle from "./about-service-wrap.style"

const AboutServiceWrap = ({ children }) => {
  return <AboutServiceWrapStyle>{children}</AboutServiceWrapStyle>
}

AboutServiceWrap.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AboutServiceWrap
