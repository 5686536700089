import React, { useState, Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Heading from "../../../components/Heading"
import { Row, Col, Box, VideoSectionWrap } from "./style"
import ModalVideo from "../../../components/modal-video"
import VideoButton from "../../../components/video-button"
import Container from "../../../components/UI/Container"

const VideoPresentacion = () => {
  const videoDataQuery = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "video-bg.jpg" }) {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      prismicInformacion(data: { mapa: {}, video_youtube: { text: {} } }) {
        data {
          video_youtube {
            text
          }
        }
      }
    }
  `)
  const imageData = videoDataQuery.file.childImageSharp.fluid
  const youtube = videoDataQuery.prismicInformacion.data.video_youtube.text
  const [videoOpen, setVideoOpen] = useState(false)
  const modalVideoOpen = () => {
    setVideoOpen(true)
  }
  const modalVideoClose = () => {
    setVideoOpen(false)
  }
  return (
    <Fragment>
      <VideoSectionWrap fluid={imageData}>
        <Container>
          <Row>
            <Col>
              <Box>
                <Heading>Ver video de Presentación</Heading>
                <VideoButton
                  skin="primary"
                  textStyle={2}
                  onClick={modalVideoOpen}
                  text="Ver Video <br/> De Presentación"
                />
              </Box>
            </Col>
          </Row>
        </Container>
      </VideoSectionWrap>
      <ModalVideo
        channel="youtube"
        videoId={youtube}
        isOpen={videoOpen}
        onClose={modalVideoClose}
      />
    </Fragment>
  )
}
export default VideoPresentacion
