import React from "react"
import FullWideSlider from "../components/flexible-image-slider/full-wide-slider"
import AboutServiceWrap from "../containers/home/about-service-wrap"
import AboutArea from "../containers/home/about-area"
import VideoPresentacion from "../containers/home/video-presentacion"
import SectionThree from "../containers/home/section-three"
import ProductsArea from "../containers/home/products-area"
import ServiciosArea from "../containers/home/servicios-area"
import MarcasArea from "../components/flexible-image-slider/carousel-slider"
import ResourcesArea from "../containers/home/resources-area"
import Newsletter from "../components/newsletter/newsletter"
import SEO from "../components/seo"

const IndexPage = () => (
  <>
    <SEO title="Llantas industriales, agrícolas, mineras y de transporte" />
    <main className="site-wrapper-reveal">
      <FullWideSlider />
      <AboutServiceWrap>
        <AboutArea />
      </AboutServiceWrap>
      <VideoPresentacion />
      <SectionThree />
      <ProductsArea />
      <ServiciosArea />
      <MarcasArea />
      <ResourcesArea />
      <Newsletter />
    </main>
  </>
)

export default IndexPage
